<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">顾客列表</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
            dense
            label="输入手机号查询"
            solo
            @input="changeSearch"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
<!--        <v-btn color="primary lighten-3" class="mr-5" @click="addItem">新增</v-btn>-->
        <v-btn color="primary" @click="doGet(1)">查询</v-btn>
      </v-col>
    </v-row>
    <v-card flat min-height="300" tile>
      <!--      <v-card-title>-->
      <!--        <v-text-field-->
      <!--            v-model="search"-->
      <!--            append-icon="mdi-magnify"-->
      <!--            label="Search"-->
      <!--            single-line-->
      <!--            hide-details-->
      <!--        ></v-text-field>-->
      <!--      </v-card-title>-->
      <v-data-table
          :headers="headers"
          :items="desserts"
      >
        <template v-slot:item.update_time="{ item }">
          {{ item.update_time | dateFormat('YYYY-MM-DD HH:mm') }}
        </template>
<!--        <template v-slot:item.action="{item}">-->
<!--          <v-btn color="primary" class="mr-1" small @click="editItem(item.id)">编辑</v-btn>-->
<!--          <v-btn color="error" small @click="deleteItem(item.id)">删除</v-btn>-->
<!--        </template>-->
      </v-data-table>
    </v-card>
    <div v-if="refresh" class="pagination">
      <v-pagination
          v-if="desserts.length > 0"
          v-model="search.page"
          :length="pageCount"
          @input="(e) => doGet(e)"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import {getOnly,getUser} from "../../api/customers";
import _ from "lodash";

export default {
  name: "index",
  data() {
    return {
      refresh: true,
      search: {
        page: 1,
        pagesize: 10,
        search:'',
      },
      total: 0,
      headers: [
        {
          text: 'id',
          align: 'start',
          filterable: true,
          value: 'id',
        },
        {
          text: '姓名',
          value: 'username'
        },
        {
          text: '手机号码',
          value: 'phone'
        },
        {text: '邮箱', value: 'email'},
        {text: '昵称', value: 'nickname'},
        // {text: '操作', value: 'action'},
      ],
      desserts: [

      ],
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.search.pagesize);
    },
  },
  mounted() {
    this.doGet()
  },
  methods:{
    async doGet(page = this.search.page) {
      const {results,count}=await getUser({ ...this.search, page })
      this.desserts = results;
      this.total = count;
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
      console.log(this.desserts)
    },
    changeSearch: _.debounce(function (e) {
      this.search.search=e
      this.doGet()
    }, 500),
    //
    // editItem(id) {
    //   this.$router.push({ name: "edit_inventory", params: { id } });
    // },
    // async deleteItem(id){
    //   await del(id)
    //   await this.doGet()
    // },
    // addItem(){
    //   this.$router.push({ name: "add_inventory"});
    // }
  }
}
</script>

<style scoped>

</style>